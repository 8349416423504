import { render, staticRenderFns } from "./AiSettingsBox.vue?vue&type=template&id=524e405a&scoped=true&"
import script from "./AiSettingsBox.vue?vue&type=script&lang=ts&"
export * from "./AiSettingsBox.vue?vue&type=script&lang=ts&"
import style0 from "./AiSettingsBox.vue?vue&type=style&index=0&id=524e405a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "524e405a",
  null
  
)

export default component.exports