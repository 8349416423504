import { render, staticRenderFns } from "./EditBasicAskOpinion.vue?vue&type=template&id=07998aa6&scoped=true&"
import script from "./EditBasicAskOpinion.vue?vue&type=script&lang=ts&"
export * from "./EditBasicAskOpinion.vue?vue&type=script&lang=ts&"
import style0 from "./EditBasicAskOpinion.vue?vue&type=style&index=0&id=07998aa6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07998aa6",
  null
  
)

export default component.exports