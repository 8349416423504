













import { defineComponent, inject, reactive, ref } from '@vue/composition-api'
import Editor from '@/components/Editor.vue'
import TextCheckbox from './components/TextCheckbox.vue'
import { EntityType } from '@/api/project/model'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
import { typeDurationOption } from '@/api/project/duration-option'
export default defineComponent({
    components: {
        Editor,
        TextCheckbox,
        TimeCustomSelect
    },
    setup() {
        const itemObj: any = inject('itemObj')
        itemObj.message_entity.entity_type = EntityType.rank
        itemObj.message_entity.duration = typeDurationOption[EntityType.rank].value

        return {
            EntityType,
            itemObj
        }
    }
})
