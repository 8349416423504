
































































































import { defineComponent, inject, ref,onMounted } from '@vue/composition-api'
import { useProjectStore } from '@/pinia/modules/project'
import { storeToRefs } from 'pinia'
import { LocaleType } from '@/interface/common'
import { Switch, Checkbox } from 'element-ui'
import { EntityType } from '@/api/project/model'
import { wordColors, wordShapes } from '@/utils/word-cloud'
import { entityTypeTemplate } from '@/api/project/entity-type-template'
import WordCloudShape from '@/components/WordCloudShape.vue'
import { cloneDeep } from 'lodash'

export default defineComponent({
    components: {
        [Switch.name]: Switch,
        [Checkbox.name]: Checkbox,
        WordCloudShape
    },
    setup(props, { root }) {
        const projectStore = useProjectStore()
        const { projectInfo } = storeToRefs(projectStore)
        const itemObj: any = inject('itemObj')
        const active = ref(false)
        const userPromptValue = ref()
         const localeOptions:any = ref([])
        const buildAndSortLocaleOptions = () => {
            let options = [
                {
                    name: root.$i18n.t('locale.en'),
                    value: LocaleType.en_US
                },
                {
                    name: root.$i18n.t('locale.zh_CN'),
                    value: LocaleType.zh_CN
                },
                {
                    name: root.$i18n.t('locale.zh_TW'),
                    value: LocaleType.zh_TW
                },
                {
                    name: root.$i18n.t('locale.vi_VN'),
                    value: LocaleType.vi_VN
                },
                {
                    name: root.$i18n.t('locale.fr_FR'),
                    value: LocaleType.fr_FR
                },
                {
                    name: root.$i18n.t('locale.es_ES'),
                    value: LocaleType.es_ES
                },
                {
                    name: root.$i18n.t('locale.id_ID'),
                    value: LocaleType.id_ID
                }, {
                    name: root.$i18n.t('locale.tr_TR'),
                    value: LocaleType.tr_TR
                }, {
                    name: root.$i18n.t('locale.th_TH'),
                    value: LocaleType.th_TH
                }, {
                    name: root.$i18n.t('locale.ja_JP'),
                    value: LocaleType.ja_JP
                }, {
                    name: root.$i18n.t('locale.es_MX'),
                    value: LocaleType.es_MX
                }, {
                    name: root.$i18n.t('locale.ms_MY'),
                    value: LocaleType.ms_MY
                },{
                    name: root.$i18n.t('locale.sv_SE'),
                    value: LocaleType.sv_SE
                }
            ];

            // 获取所有名称并排序
           const sortedOptions = options.sort((a:any, b:any) => a.name.localeCompare(b.name));
            
            localeOptions.value = sortedOptions;
        }
        if (!itemObj.message_entity.nlp_settings) {
            itemObj.message_entity.nlp_settings = cloneDeep(entityTypeTemplate.nlp_settings)
        }
        itemObj.message_entity.ai_settings= projectInfo.value.ai_settings[EntityType.ask_opinion]
        // if (itemObj.message_entity.ai_settings.user_prompt == '') {
        //     itemObj.message_entity.ai_settings.user_prompt = projectInfo.value.ai_settings[itemObj.message_entity.entity_type].user_prompt
        // }

        itemObj.message_entity.nlp_settings.apply_global_color = false
        itemObj.message_entity.nlp_settings.apply_global_shape = false
        const onSelectColor = (item: { color: string; list: string[] }) => {
            itemObj.message_entity.nlp_settings.color = item.color
        }
        const onSelectShape = (item: { icon: string; shape: string }) => {
            itemObj.message_entity.nlp_settings.shape = item.shape
        }
        const isCollapse = ref(true)

        const changeActive = () => {
            active.value = true
            setTimeout(() => {
                if( userPromptValue.value&& userPromptValue.value.$el){
                const textarea = userPromptValue.value.$el.querySelector('.el-textarea__inner');
                let height = 74
                const currentHeight = textarea.scrollHeight;
                if (currentHeight > 92 && currentHeight < 115) {
                    height = 95
                } else if (currentHeight >= 115 && currentHeight < 136) {
                    height = 117
                } else if (currentHeight >= 136) {
                    height = 140
                }
                textarea.style.height = `${height}px`;
            }
            }, 0);
        }
        onMounted(() => {  
            buildAndSortLocaleOptions()
        })
        return {
            isCollapse,
            itemObj,
            onSelectColor,
            onSelectShape,
            wordColors,
            wordShapes,
            localeOptions,
            EntityType,
            active,
            projectInfo,
            userPromptValue,
            changeActive,
            buildAndSortLocaleOptions
        }
    }
})
