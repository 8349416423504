




import { publishProjectApi } from '@/api/project'
import { showError } from '@/utils/common'
import { computed, defineComponent, inject } from '@vue/composition-api'

export default defineComponent({
    props: {
        projectInfo: {
            type: Object,
            default: {}
        }
    },
    setup(props, { root, emit }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const baseObj: any = inject('baseObj')
        console.log(`ProjectPublish allowPulish ${baseObj}`)
        const startProject = async () => {
            if (!props.projectInfo.detail_expect_at) {
                showError(root.$i18n.t('projectLive.publishInfo.msg1'))
                return
            }
            if (baseObj.polls.length === 0) {
                showError(root.$i18n.t('projectLive.publishInfo.msg2'))
                return
            }
            if (!baseObj.allowPulish) {
                showError(root.$i18n.t('projectLive.publishInfo.msg3'))
                return
            }
            root.$msgbox({
                showCancelButton: true,
                message: `${root.$t('projectLive.publishConfirm')}`,
                confirmButtonText: `${root.$t('projectLive.publishConfirmBtn')}`,
                async beforeClose(action, instance, done) {
                    if (action === 'confirm') {
                        try {
                            instance.confirmButtonLoading = true
                            await publishProjectApi({
                                uuid: projectUuId.value
                            })
                            root.$message.success(<string>root.$i18n.t('projectLive.publishInfo.msg4'))
                            emit('onSuccess')
                            done()
                            instance.confirmButtonLoading = false
                        } catch (error) {
                            showError(error)
                        }
                    } else {
                        done()
                    }
                }
            }).catch(() => {})
        }
        return {
            startProject
        }
    }
})
