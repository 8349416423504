







import { defineComponent, inject, ref } from '@vue/composition-api'
import Editor from '@/components/Editor.vue'
import { EntityType } from '@/api/project/model'
import { typeDurationOption } from '@/api/project/duration-option'
import SettingBox from '@/components/SettingBox.vue'

export default defineComponent({
    components: {
        Editor,
        SettingBox
    },
    setup() {
        const itemObj: any = inject('itemObj')
        const itemWay: any = inject('itemWay')
        if (itemWay.value == 'create') {
            itemObj.message_entity.entity_type = EntityType.onboarding_ask_opinion
            itemObj.message_entity.duration = typeDurationOption[EntityType.onboarding_ask_opinion].value
        }
        const setIsCollapse = ref(true)
        return {
            itemObj,
            EntityType,
            setIsCollapse
        }
    }
})
