
















import { EntityType, EntityTypeOption, ProjectFormType } from '@/api/project/model'
import { basicItems, sessionItems } from '@/api/project/entity-type-option'
import { computed, defineComponent, Ref, ref, unref, watch } from '@vue/composition-api'

export default defineComponent({
    props: {
        value: String,
        formType: {
            type: String,
            default: ProjectFormType.Basic
        }
    },
    setup(props, { emit }) {
        const optionItems: Ref<EntityTypeOption[]> = ref([])

        if (props.formType == ProjectFormType.Basic) {
            optionItems.value = [...basicItems]
        } else if (props.formType == ProjectFormType.Session) {
            optionItems.value = [...sessionItems]
        }

        if (props.value != EntityType.branch) {
            optionItems.value = optionItems.value.filter((v) => EntityType.branch != v.value)
        }


        const optionIcon = computed(() => {
            return optionItems.value.find((v) => v.value == props.value)?.icon || ''
        })
        const optionValue = ref(props.value)
        watch(
            () => props.value,
            (val) => {
                optionValue.value = val
            }
        )

        const handleEntityType = (val: EntityType) => {
            emit('handleEntityType', val)
        }
        return {
            EntityType,
            optionItems,
            optionIcon,
            handleEntityType,
            optionValue
        }
    }
})
