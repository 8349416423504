




























import { computed, defineComponent, inject, provide, ref } from '@vue/composition-api'
import { useProjectStore } from '@/pinia/modules/project'
import { storeToRefs } from 'pinia'
import TypeSelect from '@/components/form-common-send/TypeSelect.vue'
import SendSpeak from '@/components/form-common-send/SendSpeak.vue'
import SendPoll from '@/components/form-common-send/SendPoll.vue'
import SendAskOpinion from '@/components/form-common-send/SendAskOpinion.vue'
import SendAskExperience from '@/components/form-common-send/SendAskExperience.vue'
import SendRank from '@/components/form-common-send/SendRank.vue'
import SendImage from '@/components/form-common-send/SendImage.vue'
import SendVideo from '@/components/form-common-send/SendVideo.vue'
import SendUpload from '@/components/form-common-send/SendUpload.vue'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { verifyMessageEntity } from '@/utils/verify-message-entity'
import ErrorMessage from '@/components/ErrorMessage.vue'

export default defineComponent({
    components: {
        TypeSelect,
        SendSpeak,
        SendPoll,
        SendAskOpinion,
        SendAskExperience,
        SendRank,
        SendImage,
        SendVideo,
        SendUpload,
        ErrorMessage
    },
    props: {},
    setup(props, { emit }) {
        const sendObj: any = inject('sendObj')
        sendObj.itemObj.message_entity.duration=10
        const itemObj = sendObj.itemObj

        provide('itemObj', itemObj)
        const projectStore = useProjectStore()
        const { projectInfo } = storeToRefs(projectStore)
        const submitLoad = ref(false)
        const errorMessage = ref()
        const conversationObj: any = inject('conversationObj')
        const liveObj: any = inject('liveObj')

        const sendItem = () => {
            if (liveObj.conversationList.length == 0) {
                const firstSection = conversationObj.sections[0]
                itemObj.guide_id = firstSection.guide_id
                itemObj.project_uuid = firstSection.project_uuid
                itemObj.section_id = firstSection.id
                itemObj.sequence = 0
            } else if (sendObj.lastItem) {
                itemObj.guide_id = sendObj.lastItem.guide_id
                itemObj.project_uuid = sendObj.lastItem.project_uuid
                itemObj.section_id = sendObj.lastItem.section_id
                itemObj.sequence = sendObj.lastItem.sequence >= 0 ? sendObj.lastItem.sequence + 1 : 0
            } else {
                const lastSection = conversationObj.sections[conversationObj.sections.length - 1]
                itemObj.guide_id = lastSection.guide_id
                itemObj.project_uuid = lastSection.project_uuid
                itemObj.section_id = lastSection.id
                itemObj.sequence = lastSection.items.length
            }

            itemObj.message_entity.entity_type = itemObj.message_entity.entity_type || sendObj.typeInfo.type
          
            errorMessage.value = verifyMessageEntity(itemObj)
            if (errorMessage.value) {
                return
            }

            try {

                emit('send', itemObj)
            } catch (e) {}
        }


        const sendDisable = computed(() => {
            if (sendObj.sendingId) {
                return true
            }
            if (liveObj.timeInfo.timeRemain > 0) {
                return true
            }
            return false
        })

        return {
            submitLoad,
            ProjectFormType,
            EntityType,
            sendItem,
            sendObj,
            errorMessage,
            sendDisable,
            projectInfo
        }
    }
})
