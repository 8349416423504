




















































































import { uploadVideo } from '@/api/common'
import { showError } from '@/utils/common'
import { videoTypes, uploadHandle } from '@/utils/upload'
import { computed, defineComponent, inject, reactive, Ref, ref, set, nextTick } from '@vue/composition-api'
import { Upload } from 'element-ui'
import { timeFormatStr } from '@/utils/time-format'

export default defineComponent({
    components: {
        [Upload.name]: Upload,
    },
    props: {
        isShowRight: {
            type: Boolean,
            default: true
        },
        option:{
            type: Boolean,
            default: true
        },
        upLoadNumber:{
            type: Number,
            default: 50
        },
        useFileName:{
            type: Boolean,
            default: false
        }
    },
    setup(props, { root, refs }) {
        const isCollapse = ref(true)
        const setIsCollapse = ref(true)
        const itemObj: any = inject('itemObj')

        const optionObj = reactive({
            videos: computed(() => itemObj?.message_entity.options.filter((v: any) => !v.is_system)),
            systemOptions: computed(() => itemObj?.message_entity.options.filter((v: any) => v.is_system))
        })

        const previewList = computed(() => itemObj.message_entity.options.map((v: any) => v.link))

        const videoUploadInfo = reactive({
            loading: false,
            duration:'',
            index: null as null | number,
            before: (file: File) => {
                return uploadHandle(file, 2)
            },
            request: async (value: any) => {
                videoUploadInfo.loading = true
                try {
                    const { url } = await uploadVideo({
                        file: value.file
                    })
                    const videoDuration = await getVideoDuration(url);
                    let location = 0
                    if (value.data.type == 'edit') {
                        itemObj.message_entity.options[value.data.index].link = url
                        itemObj.message_entity.options[value.data.index].duration =videoDuration
                        location = value.data.index
                    } else {
                        const index = itemObj.message_entity.options.filter((v: any) => !v.is_system).length
                        const data = {
                            link: url,
                             text:props.useFileName?value.file.name.substring(0, value.file.name.lastIndexOf('.')): `${root.$i18n.t('project.video')}${index + 1}`,
                             duration: videoDuration
                        }
                        if (value.data.index >= 0) {
                            location = value.data.index + 1
                            itemObj.message_entity.options.insert(location, data)
                        } else {

                            const options = itemObj.message_entity.options.filter((item: any) => !item.is_system)
                            location = options.length
                            itemObj.message_entity.options.insert(location, data)
                        }
                    }
                    nextTick(() => {
                        const input = document.getElementById(`optionInputRef${url}`)
                        input?.focus()
                    })
                } catch (error) {
                    showError(error)
                }
                videoUploadInfo.loading = false
            }
        })
        const getVideoDuration = async (url: string): Promise<number> => {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.src = url;
                video.onloadedmetadata = function () {
                    resolve(Math.ceil(video.duration));
                };
                video.onerror = function () {
                    reject(new Error('Failed to load video metadata'));
                };
            });
        };

        const onVideoCanplay = (id:string) => {
            nextTick(() => {
                const videoRef = document.getElementById(`videoRef-${id}`) as HTMLVideoElement
                if (videoRef) {
                    videoUploadInfo.duration = timeFormatStr(videoRef.duration)
                }
            })
        }

        const onDeleteOption = async (item: any, index: number) => {
            itemObj.message_entity.options.splice(index, 1)
        }

        return {
            itemObj,
            previewList,
            videoTypes,
            videoUploadInfo,
            isCollapse,
            setIsCollapse,
            onDeleteOption,
            optionObj,
            onVideoCanplay
        }
    }
})
