



































import { defineComponent, provide, reactive, ref, set } from '@vue/composition-api'
import { basicItems } from '@/api/project/entity-type-option'
import { EntityType, EntityTypeOption } from '@/api/project/model'
import EditBasicAskOpinion from './EditBasicAskOpinion.vue'
import EditBasicPoll from './EditBasicPoll.vue'
import { updateOrCreateOnboardingPollApi } from '@/api/project'
import { showError } from '@/utils/common'
import { verifyMessageEntity } from '@/utils/verify-message-entity'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { entityTypeTemplate } from '@/api/project/entity-type-template'
import _ from 'lodash'
export default defineComponent({
    components: {
        EditBasicAskOpinion,
        EditBasicPoll,
        ErrorMessage
    },
    props: {},
    setup(props, { emit }) {
        const errorMessage = ref()
        const submitLoad = ref(false)
        const showDialog = ref(false)
        const optionItems = ref()
        const itemObj: any = reactive({
            id: '',
            guide_id: '',
            project_uuid: '',
            section_id: '',
            sequence: '',
            message_entity: _.cloneDeep(entityTypeTemplate)
        })

        provide('itemObj', itemObj)

        const itemWay = ref()
        provide('itemWay', itemWay)

        const initItem = () => {
            const info: any = {
                id: '',
                guide_id: '',
                project_uuid: '',
                section_id: '',
                sequence: '',
                message_entity: _.cloneDeep(entityTypeTemplate)
            }
            Object.keys(info).forEach((key) => {
                itemObj[key] = info[key]
            })
            errorMessage.value = ''
        }
        const initMessageEntity = (retainContent: boolean = false) => {
            const entityTypeTemplateCopy = _.cloneDeep(entityTypeTemplate)
            Object.keys(entityTypeTemplateCopy).forEach((key: any) => {
                if (key == 'settings') {
                    if (itemObj.message_entity[key].length > 0) {
                        itemObj.message_entity[key][0].options = {
                            none: false,
                            other: false,
                            randomized: false
                        }
                        itemObj.message_entity[key][0].ai_enable = true
                        itemObj.message_entity[key][0].recommend_enable = true
                    } else {
                        itemObj.message_entity[key].push({
                            ai_enable: true,
                            options: {
                                randomized: false,
                                other: false,
                                none: false
                            },
                            recommend_enable: true,
                        })
                    }
                } else if (key == 'content' && retainContent) {

                } else {
                    itemObj.message_entity[key] = entityTypeTemplateCopy[key]
                }
            })
        }

        const initFun = (item: any, type: string) => {
            itemWay.value = type
            Object.keys(itemObj).forEach((key) => {
                set(itemObj, key, item[key])
            })
            if (itemWay.value == 'create') {
                initMessageEntity()
                itemObj.id = undefined
                itemObj.message_entity.entity_type = EntityType.onboarding_ask_opinion
                itemObj.sequence = item.sequence + 1
                optionItems.value = basicItems
            } else if (itemWay.value == 'edit') {
                if (
                    [EntityType.onboarding_poll, EntityType.onboarding_poll_multi, EntityType.onboarding_poll_picture, EntityType.onboarding_poll_picture_multi].includes(
                        itemObj.message_entity.entity_type
                    )
                ) {
                    optionItems.value = basicItems.filter((v) => v.value == EntityType.onboarding_poll)
                } else {
                    optionItems.value = basicItems.filter((v) => v.value == item.message_entity.entity_type)
                }
            }
            showDialog.value = true
        }

        const onChangeOption = (item: EntityTypeOption) => {
            if (itemObj.message_entity.entity_type == item.value) {
                return
            }
            initMessageEntity(true)
            itemObj.message_entity.entity_type = item.value
            errorMessage.value = ''
        }

        const isOptionActive = (item: EntityTypeOption) => {
            if (
                [EntityType.onboarding_poll, EntityType.onboarding_poll_multi, EntityType.onboarding_poll_picture, EntityType.onboarding_poll_picture_multi].includes(
                    itemObj.message_entity.entity_type
                )
            ) {
                return item.value == EntityType.onboarding_poll
            }
            return item.value == itemObj.message_entity.entity_type
        }

        const submitSend = async () => {
            errorMessage.value = verifyMessageEntity(itemObj)
            if (errorMessage.value) {
                return
            }
            handleItemObj()
            submitLoad.value = true
            try {
                const data = await updateOrCreateOnboardingPollApi(itemObj)
                emit('success', itemWay.value, data)
                showDialog.value = false
            } catch (error) {
                showError(error)
            }
            submitLoad.value = false
        }

        const handleItemObj = () => {
            itemObj.message_entity.options?.forEach((v: any, i: number) => {
                v.sequence = i
            })
        }

        return {
            errorMessage,
            EntityType,
            showDialog,
            optionItems,
            itemObj,
            initFun,
            onChangeOption,
            isOptionActive,
            submitSend,
            submitLoad,
            initItem
        }
    }
})
