













import { uploadVideo } from '@/api/common'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { showError } from '@/utils/common'
import { videoTypes, uploadHandle } from '@/utils/upload'
import { defineComponent, inject, nextTick, reactive } from '@vue/composition-api'
import { Upload } from 'element-ui'
import { timeFormatStr } from '@/utils/time-format'
import VideoCheckbox from './components/VideoCheckbox.vue'
import Editor from '@/components/Editor.vue'

export default defineComponent({
    components: {
        [Upload.name]: Upload,
        VideoCheckbox,
        Editor
    },
    setup() {
        const itemObj: any = inject('itemObj')
        itemObj.message_entity.entity_type = EntityType.video
        const videoUploadInfo = reactive({
            loading: false,
            duration: '',
            before: (file: File) => {
                return uploadHandle(file, 2)
            },
            request: async (value: any) => {
                videoUploadInfo.loading = true
                try {
                    const { url } = await uploadVideo({
                        file: value.file
                    })
                    const videoDuration = await getVideoDuration(url);
                    itemObj.message_entity.link = url
                    itemObj.message_entity.duration = videoDuration
                } catch (error) {
                    showError(error)
                }
                videoUploadInfo.loading = false
            }
        })
        const getVideoDuration = async (url: string): Promise<number> => {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.src = url;
                video.onloadedmetadata = function () {
                    resolve(Math.ceil(video.duration));
                };
                video.onerror = function () {
                    reject(new Error('Failed to load video metadata'));
                };
            });
        };
        const onVideoCanplay = () => {
            nextTick(() => {
                const videoRef = document.getElementById('videoRef') as HTMLVideoElement
                if (videoRef) {
                    itemObj.message_entity.duration = Math.round(videoRef.duration)
                    videoUploadInfo.duration = timeFormatStr(videoRef.duration)
                }
            })
        }
        return {
            EntityType,
            videoTypes,
            videoUploadInfo,
            onVideoCanplay,
            itemObj
        }
    }
})
