

























































import { showError } from '@/utils/common'
import { defineComponent, reactive, ref, computed } from '@vue/composition-api'
import { Form, FormItem } from 'element-ui'
import { endProjectApi } from '@/api/project/index'
import { useUserStore } from '@/pinia/modules/user'

export default defineComponent({
    components: {
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    setup(props, { root, emit }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const userStore = useUserStore()
        const formRules = {
            email: [
                { required: true, message: root.$t('shareDialog.formRule1'), trigger: 'blur' },
                { type: 'email', message: root.$t('shareDialog.formRule2'), trigger: ['blur', 'change'] },
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (value.toLowerCase() !== userStore.userInfo.email?.toLowerCase()) {
                            callback(new Error(root.$t('closePopover.formRule3').toString()))
                        } else {
                            callback()
                        }
                    },
                    trigger: ['blur', 'change']
                }
            ]
        }
        const formData = reactive({
            email: ''
        })
        const formRef = ref()
        const confirmInfo = reactive({
            showDialog: false,
            loading: false,
            onSubmit: async () => {
                formRef.value
                    .validate()
                    .then(async () => {
                        confirmInfo.loading = true
                        try {
                            await endProjectApi({
                                uuid: projectUuId.value
                            })
                            emit('success')
                            confirmInfo.showDialog = false
                        } catch (error) {
                            showError(error)
                        }
                        confirmInfo.loading = false
                    })
                    .catch(() => {})
            }
        })

        return { confirmInfo, formRules, formData, formRef }
    }
})
