





























import { defineComponent, inject, reactive, ref, watch } from '@vue/composition-api'
import Editor from '@/components/Editor.vue'
import TextCheckbox from './components/TextCheckbox.vue'
import ImageCheckbox from './components/ImageCheckbox.vue'
import { EntityType } from '@/api/project/model'
import { sesstionUploadItems } from '@/api/project/entity-type-option'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
import { typeDurationOption } from '@/api/project/duration-option'
import AiSettingsBox from './components/AiSettingsBox.vue'
export default defineComponent({
    components: {
        Editor,
        TextCheckbox,
        ImageCheckbox,
        TimeCustomSelect,
        AiSettingsBox
    },
    setup() {
        const itemObj: any = inject('itemObj')
        itemObj.message_entity.entity_type = EntityType.upload_image
        itemObj.message_entity.duration = typeDurationOption[EntityType.upload_image].value
        const timeCustomSelectRef = ref()
        const entityTypeInfo = reactive({
            value: EntityType.poll,
            oldValue: itemObj.message_entity.entity_type,
            option: sesstionUploadItems,
            change: () => {
                entityTypeInfo.oldValue = itemObj.message_entity.entity_type

                if (itemObj.message_entity.entity_type === EntityType.upload_video && typeDurationOption[itemObj.message_entity.entity_type].value) {
                    itemObj.message_entity.duration = typeDurationOption[itemObj.message_entity.entity_type].value
                } else {
                    itemObj.message_entity.duration = typeDurationOption[EntityType.upload_image].value
                }

                timeCustomSelectRef.value.timeInfo.value = itemObj.message_entity.duration
            }
        })

        return {
            EntityType,
            itemObj,
            entityTypeInfo,
            timeCustomSelectRef
        }
    }
})
